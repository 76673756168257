import React from 'react'
import './footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="contact-content">
            <h2><b>Get In Touch</b></h2>
          <div className="footer-content">
            <div className="contact-container">
                
              <div className="contact-item">
                <strong>Phone:</strong> +91 8860792969
              </div>
              <div className="contact-item">
                <strong>Email:</strong> tauk.live@gmail.com
              </div>
              <div className="contact-item">
                <strong>Location:</strong> Bangalore, India
              </div>
            </div>
          </div>
          </div>
        </footer>
      );
}

export default Footer