import React, { useState, useEffect } from 'react';

import './App.css';
import Header from './components/header';
import Home from './components/home';
import Features from './components/features';
import Pricing from './components/pricing'; 
import Demo from './components/demo'; 
import Widget from './chat-widget';
import Footer from './components/footer';

const App = () => {
  const [activeSection, setActiveSection] = useState('home');

  const handleScroll = () => {
    const sections = document.querySelectorAll('section');
    const scrollPos = window.scrollY + window.innerHeight / 2;

    sections.forEach(section => {
      if (scrollPos >= section.offsetTop && scrollPos < section.offsetTop + section.offsetHeight) {
        setActiveSection(section.getAttribute('id'));
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);





  return (
    <div id="root">
      <div className="container">
        <Header activeSection={activeSection} />
        <section id="home">
          <Home />
        </section>
        <section id="features">
          <Features />
        </section>
        <section id="demo">
          <Demo />
        </section>
        <section id="pricing">
          <Pricing />
        </section>
        <Widget/>
        <Footer />
      </div>
    </div>
  );
};

export default App;
