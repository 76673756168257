import { ChatSection} from "tauk_live_bot"

function Widget() {
  const mockButtonStyles = {
    colorScheme: "green.500",
    buttonWidth: "50px",
    buttonHeight: "50px",
    bottom: "20px",
    right: "20px",
    userMessageColor: "green.200",
    botMessageColor: "gray.100",
  }

  const mockUniqueKbId = 17178373302;
  const mockUserInfo = {
  name: "John Doe",
  phone: 919306716859,
};


  return (
        <ChatSection
          widgeType="chat"
          buttonStyles={mockButtonStyles}
          userInfo={mockUserInfo}
          uniqueKbId={mockUniqueKbId}
        />
      
  )
}

export default Widget