import React, { useState } from 'react';
import Select, {components} from 'react-select';
import './home.css';
const  Home=()=> {
    const [countryCode, setCountryCode] = useState('+91');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [isButtonDisabled,setButtonDisabled] = useState(false);  
    const uniqueKbId = 14129601648;
    const clientName = "tauk"


  const handleCountryCodeChange = (selectedOption) => {
    if (selectedOption) {
      setCountryCode(selectedOption.value);
    }
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleConverseButtonClick = () => {
    if (phoneNumber.trim() === '') {
      alert('Your phone number is empty. Please enter a phone number.');
      return;
    }
    setButtonDisabled(true);  
    const endpoint = `https://middleware.tauk.live/call?client=${clientName}&key=%2B${uniqueKbId}&sessionId=${countryCode}${phoneNumber}`;
    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      
    })
    .then((response) => {
      if (response.ok) {
        console.log('Success:');
        return;
      }
      return Promise.reject({
        message: `Failed to fetch Response from Xero `,
        statusCode: response.status,
      });
    });
  };

    const countryOptions = [
        { value: '+1', label: '+1 (US)' },
        { value: '+91', label: '+91 (India)' },
        { value: '+44', label: '+44 (UK)' },
        { value: '+61', label: '+61 (Australia)' },
        { value: '+81', label: '+81 (Japan)' },
        { value: '+49', label: '+49 (Germany)' },
        { value: '+33', label: '+33 (France)' },
        { value: '+39', label: '+39 (Italy)' },
        { value: '+86', label: '+86 (China)' },
        { value: '+82', label: '+82 (South Korea)' },
        { value: '+7', label: '+7 (Russia)' },
        { value: '+34', label: '+34 (Spain)' },
        { value: '+55', label: '+55 (Brazil)' },
        { value: '+64', label: '+64 (New Zealand)' },
        { value: '+27', label: '+27 (South Africa)' },
        { value: '+52', label: '+52 (Mexico)' },
        { value: '+31', label: '+31 (Netherlands)' },
        { value: '+47', label: '+47 (Norway)' },
        { value: '+46', label: '+46 (Sweden)' },
        { value: '+41', label: '+41 (Switzerland)' },
        { value: '+90', label: '+90 (Turkey)' }
      ];

      const SingleValue = ({ children, ...props }) => {
        const selectedOption = countryOptions.find(option => option.value === props.data.value);
        return (
          <components.SingleValue {...props}>
            {selectedOption ? selectedOption.value : children}
          </components.SingleValue>
        );
      };
    
  return (
    <div className="home-container"> 
      <div className="home-content">
        <h1>Empower Conversations with Tauk AI</h1>
        <h2>AI voicebot enabling your customers to converse with a smart assistant</h2>
        <div className="input-container">
            <Select
            value={countryOptions.find(option => option.value === countryCode)}
            onChange={handleCountryCodeChange}
            options={countryOptions}
            className="select-dropdown"
            components={{ SingleValue }}
            styles={{
                control: (provided) => ({
                ...provided,
                width: 70,
                height: 44.5,
                backgroundColor: 'white',
                color: 'black',
                borderColor: '#ccc',
                appearance: 'none', 
                marginRight:'2px',
                }),
                menu: (provided) => ({
                ...provided,
                marginTop: 0,
                backgroundColor: 'black',
                color: 'white',
                fontSize:13,

                }),
                menuList: (provided) => ({
                ...provided,
                backgroundColor: 'black',
                }),
                option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? 'white' : '#ddd',
                backgroundColor: state.isSelected ? '#555' : 'black',
                '&:hover': {
                    backgroundColor: '#333',
                },
                }),
                singleValue: (provided) => ({
                ...provided,
                color: 'black',
                marginTop:'-5px',
                }),
                dropdownIndicator: () => ({
                display: 'none', // Remove the dropdown indicator (arrow)
                }),
                indicatorSeparator: () => ({
                display: 'none', // Remove the separator
                }),
                
            }}
            menuPlacement="bottom"
            isSearchable={false}  
            />
            <input type="text" placeholder="Enter your phone number"  value={phoneNumber}
            onChange={handlePhoneNumberChange}/>
            {isButtonDisabled ? <button disabled>Calling...</button> : <button onClick={handleConverseButtonClick}>Recieve a call</button>}
        </div>
      </div> 
   </div>
  )
}

export default Home
