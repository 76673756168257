import React from 'react'
import multilingualIcon from "../assets/multilingual-icon.png"; // Adjust path if necessary
import clockRoundSupport from "../assets/roundtheClock.png"
import kbTrain from '../assets/kb-train.png';
import integrateApi from '../assets/widgetInt.png';
import './features.css';

function Features() {
  return (
    <div className="feature-container"> 
        <div className="feature-content">
            <h1>Explore Our Key Features</h1>
            <h2>Deliver Exceptional Customer Experiences with Tauk's Cutting Edge Capabilities</h2>
        </div>
        <div className="card-container">
            <div className="card">
            <img src={multilingualIcon} alt="Feature 1 Icon" />
            <h3>Multilingual Support and Streamlined Analytics</h3>
            <p>Enabling seamless communication in your customers' preferred languages. With advanced analytics gain valuable insights into customer interactions to improve service quality.</p>
            </div>
            <div className="card">
            <img src={clockRoundSupport} alt="Feature 2 Icon" />
            <h3>Unlimited Scalability and 24/7 Availability</h3>
            <p>Support unlimited customer agents, accommodating businesses of any size. Provide your customers with uninterrupted support anytime, enhancing satisfaction.</p>
            </div>
        </div>
        <div className="card-container">
            <div className="card">
            <img src={integrateApi} alt="Feature 3 Icon" />
            <h3>Seamless Integration via Widgets or API</h3>
            <p>Integrate effortlessly with your existing systems using our customizable widget or robust API. Enjoy a consistent user experience across all customer touchpoints..</p>
            </div>
            <div className="card">
            <img src={kbTrain} alt="Feature 4 Icon" />
            <h3>Automatic Knowledge Base Integration and Training</h3>
            <p> Deliver accurate, up-to-date answers to customers, enhancing support efficiency and effectiveness.</p>
            </div>
        </div>
    </div>
  )
}

export default Features