import React from 'react'
import './demo.css';

function Demo() {
  return (
    <div className="demo-container"> 
        <div className="demo-content">
            <h1>Let's see how it works?</h1>
        </div>
        <div className="video-container">
        <iframe 
                src="https://www.youtube.com/embed/reUZRyXxUs4" 
                frameBorder="0" 
                allow="autoplay; fullscreen" 
                allowFullScreen
                title="Loom Video"
          > 
          </iframe>
        </div>
    </div>
  )
}

export default Demo
