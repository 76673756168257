import React,{useState} from 'react'
import logo from '../assets/logo.png';
import menuIcon from '../assets/hamburger.png'
import './header.css';
function Header(activeSection) {

  const HREF_LINK ="https://wa.me/918860792969?text=I%20would%20like%20to%20know%20about%20Tauk%20AI";
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
        <header className="sticky-header">
            <nav>
            <img src={logo} alt="Logo" className="header-logo" />
            <h2 style={{fontSize:'x-large'}}><b>Tauk</b></h2>
            <div className="list-component">
            <ul>
                <li className={activeSection === 'home' ? 'active' : ''}><a href="#home">Home</a></li>
                <li className={activeSection === 'features' ? 'active' : ''}><a href="#features">Features</a></li>
                <li className={activeSection === 'demo' ? 'active' : ''}><a href="#demo">Demo</a></li>
                {/* <li className={activeSection === 'pricing' ? 'active' : ''}><a href="#pricing">Pricing</a></li> */}
                <li className={activeSection === 'contact' ? 'active' : ''}><a href={HREF_LINK}>Contact</a></li>
            </ul>
            </div>
            <div className="menu-container">
            <img src={menuIcon} alt="menu-icon" className="menu-icon" onClick={toggleMenu}/>
            {menuOpen && (
              <div className="menu">
                <ul>
                  <li><a href="#home">Home</a></li>
                  <li><a href="#features">Features</a></li>
                  <li><a href="#demo">Demo</a></li>
                  <li><a href="#pricing">Pricing</a></li>
                  <li><a href={HREF_LINK}>Contact</a></li>
                </ul>
              </div>
            )}
            </div>
            </nav>
       </header>
  )
}

export default Header