import React from 'react'
import './pricing.css'; 
import plusIcon from '../assets/plus.png';

function Pricing() {
  return (
    <div className="price-container">
          {/* <div className="pricing-content">
                <h1>Pricing Plans Designed to Grow with You</h1>
          </div> */}
          {/* <div className="pricing-grid">
            <div className="pricing-card">
              <h3><b>Fixed Incurring Cost</b></h3>
              <p>$99/month</p>
              <ul>
                <li>Dedicated Servers</li>
                <li>Integration with External knowledge Base</li>
                <li>Automated updates</li>
              </ul>
            </div>
            <img src={plusIcon} alt="Plus Icon"/>
            <div className="pricing-card">
              <h3><b>Speech Interaction Capability</b></h3>
              <p>$0.07/minute of call time</p>
              <ul>
                <li>Localized responses</li>
                <li>Accent recognition algorithms</li>
                <li>User feedback mechanism</li>
              </ul>
            </div>
            <img src={plusIcon} alt="Plus Icon" />
            <div className="pricing-card">
              <h3><b>Text Interaction Capability</b></h3>
              <p>$0.012/query answered</p>
              <ul>
                <li>Embeddable Widget</li>
                <li>Optimized NLP engine</li>
                <li>Contextual memory</li>
              </ul>
            </div>
          </div> */}
          <h5> To learn about pricing, submit an inquiry <a href="https://forms.gle/9MrKuNgbiMutp75w8" target="_blank" rel="noreferrer" style={{textDecoration:"underline"}}> here</a>.</h5>
    </div>
  )
}

export default Pricing